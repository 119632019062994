// Vendor imports
import { Fancybox } from "@fancyapps/ui"
import Alpine from "alpinejs"
import collapse from "@alpinejs/collapse"
import focus from "@alpinejs/focus"
import intersect from "@alpinejs/intersect"
import breakpoint from "alpinejs-breakpoints"
Alpine.plugin(intersect)
Alpine.plugin(breakpoint)
Alpine.plugin(collapse)
Alpine.plugin(focus)
window.Alpine = Alpine
window.AlpineBreakpointPluginBreakpointsList = [
	"xs",
	"sm",
	"md",
	"lg",
	"xl",
	"2xl",
	"3xl",
]
window.Alpine.start()

import "lazysizes/plugins/respimg/ls.respimg"
import "lazysizes/plugins/parent-fit/ls.parent-fit"
import "lazysizes"
import "lazysizes/plugins/object-fit/ls.object-fit"

// Shoelace Web Components 👟
import "./shoelace-style"

// Scroller
import './modules/scroller';

// Internal module imports
import "./modules/svgIconSprite"
import './modules/carousel';
import "./modules/google-maps"
import leaflet from "./modules/leaflet-maps"

// Initialize modules
leaflet.initMaps()
window.masterplanControls = leaflet.masterplanControls
